import React from 'react';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {PaymentRequestView} from './PaymentRequestView';
import {useControllerProps} from '../Widget/ControllerContext';

export enum ErrorViewDataHook {
  root = 'ErrorViewDataHook.root',
  view = 'ErrorViewDataHook.view',
}

export const ErrorView = () => {
  const {
    paymentRequestStore: {sendPaymentRequestErrorPageLoadBIEvent},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  sendPaymentRequestErrorPageLoadBIEvent('error');

  return (
    <div data-hook={ErrorViewDataHook.root}>
      <PaymentRequestView
        dataHook={ErrorViewDataHook.view}
        title={localeKeys.checkout.payLinks.generalError.title()}
        subtitle={localeKeys.checkout.payLinks.generalError.subtitle()}
      />
    </div>
  );
};
