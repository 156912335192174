import React from 'react';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {PaymentRequestView} from './PaymentRequestView';
import {useControllerProps} from '../Widget/ControllerContext';

export enum EmptyViewDataHook {
  root = 'EmptyViewDataHook.root',
  view = 'EmptyViewDataHook.view',
}

export const EmptyView = () => {
  const {
    paymentRequestStore: {sendPaymentRequestErrorPageLoadBIEvent},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  sendPaymentRequestErrorPageLoadBIEvent('not found');

  return (
    <div data-hook={EmptyViewDataHook.root}>
      <PaymentRequestView
        dataHook={EmptyViewDataHook.view}
        title={localeKeys.checkout.payLinks.linkNotFound.title()}
        subtitle={localeKeys.checkout.payLinks.linkNotFound.subtitle()}
      />
    </div>
  );
};
