import React, {useState} from 'react';
import {PaymentWidgetWrapper} from './PaymentWidgetWrapper';
import {classes} from './PaymentFlow.st.css';
import {classes as commonClasses} from '../../../common/common.st.css';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {Text, TextPriority} from 'wix-ui-tpa/cssVars';
import {PayNowButton} from './PayNowButton/PayNowButton';
import {MainLoader} from '../../../common/components/Layout/MainLoader/MainLoader';
import {useControllerProps} from '../Widget/ControllerContext';

export enum PaymentFlowDataHook {
  root = 'PaymentFlowDataHook.root',
  title = 'PaymentFlowDataHook.title',
}

export const PaymentFlow = () => {
  const {
    paymentRequestStore: {sendPaymentRequestPageLoadBIEvent},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();
  const [loaded, setLoaded] = useState(false);
  if (loaded) {
    sendPaymentRequestPageLoadBIEvent();
  }

  return (
    <div className={commonClasses.oneColumnViewPadding} data-hook={PaymentFlowDataHook.root}>
      <div className={classes.stepTitleContainer}>
        <div className={classes.stepTitle}>
          <Text
            tagName={'h2'}
            priority={TextPriority.primary}
            className={classes.titleText}
            data-hook={PaymentFlowDataHook.title}>
            {localeKeys.checkout.payment()}
          </Text>
        </div>
      </div>
      {!loaded && <MainLoader />}
      <PaymentWidgetWrapper setLoaded={setLoaded} loaded={loaded} />
      {loaded && <PayNowButton />}
    </div>
  );
};
