import {PaymentsWidgetConfiguration} from '@wix/cashier-payments-widget/dist/src/types/PaymentsWidgetConfiguration';
import {PaymentsWidgetProps} from '@wix/cashier-payments-widget/dist/src/types/PaymentsWidgetProps';
import {PaymentRequestSettingsModel} from '../domain/models/paymentRequest/PaymentRequestSettings.model';
import {PaymentRequestModel} from '../domain/models/paymentRequest/PaymentRequest.model';
import {PolicyButtonLocation, PolicyType} from '../common/components/PolicyButtonWithDialog/PolicyButtonWithDialog';

export type PaymentRequestControllerProps = {
  isOneColumnView?: boolean;
  isLoading: boolean;
  failedToFetch?: boolean;
  paymentRequestState?: PaymentRequestState;
  paymentRequestStore: PaymentRequestStoreProps;
  paymentRequestSettingsStore: PaymentRequestSettingsStoreProps;
  paymentStore: PaymentRequestPaymentStoreProps;
  navigationStore: PaymentRequestNavigationStoreProps;
};

export enum PaymentFlowMonitorBreadcrumbs {
  validation = 'cashier billing form is valid',
  paid = 'completed payment',
  error = 'payment error',
}

export enum PaymentResult {
  Approved = 'Approved',
  Pending = 'Pending',
}

export enum PaymentRequestState {
  paid = 'Paid',
  unpaid = 'Unpaid',
  expired = 'Expired',
  empty = 'Empty',
  error = 'Error',
}

export enum PaymentRequestErrorCode {
  notFound = 'ORDER_PAYMENT_REQUEST_NOT_FOUND',
  generalError = 'GENERAL_ERROR',
}

export interface PaymentRequestSettingsStoreProps {
  paymentRequestSettings: PaymentRequestSettingsModel;
}

export interface PaymentRequestPaymentStoreProps {
  cashierWidgetProps: PaymentsWidgetProps;
  cashierConfiguration: PaymentsWidgetConfiguration;
}

export interface PaymentRequestStoreProps {
  paymentRequest: PaymentRequestModel;
  sendPaymentRequestPageLoadBIEvent: () => void;
  sendClickOnPayNowButtonBIEvent: () => void;
  sendClickOnSummaryMobileViewSectionBIEvent: (action: string) => void;
  sendPaymentRequestThankYouPageLoadBIEvent: () => void;
  sendPaymentRequestExpiredPageLoadBIEvent: () => void;
  sendPaymentRequestErrorPageLoadBIEvent: (error: string) => void;
  sendClickOnPolicyBIEvent: (linkLocation: PolicyButtonLocation, policyType: PolicyType) => void;
}

export interface PaymentRequestNavigationStoreProps {
  navigateToThankYouPage: () => void;
  continueShoppingUrl: string;
  isSSR: boolean;
  paymentRequestState?: PaymentRequestState;
}
